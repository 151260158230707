import { Control, UseFormRegister } from 'react-hook-form';

import type { CountryCodeType } from 'utils/constants';
import type { BriefUser } from './userInterface';
import type { Enclosure, IEpisode, SatsFieldType, SocialInteract } from './episodeInterface';
import { CurrencyUnit, MongoDoc } from './general';
import { ActivityAction } from './activityInterface';

export enum PersonRole {
  HOST = 'Host',
  GUEST = 'Guest',

  AI = 'AI',
  ANNOUNCER = 'Announcer',
  ASSISTANT = 'Assistant',
  ASSISTANT_CAMERA = 'Assistant Camera',
  ASSISTANT_DIRECTOR = 'Assistant Director',
  ASSISTANT_EDITOR = 'Assistant Editor',
  ASSOCIATE_PRODUCER = 'Associate Producer',
  AUDIO_EDITOR = 'Audio Editor',
  AUDIO_ENGINEER = 'Audio Engineer',
  AUTHOR = 'Author',
  BOOKING_COORDINATOR = 'Booking Coordinator',
  CAMERA_GRIP = 'Camera Grip',
  CAMERA_OPERATOR = 'Camera Operator',
  CEO = 'CEO',
  CFO = 'CFO',
  COMMUNITY_MANAGER = 'Community Manager',
  COMPOSER = 'Composer',
  CONSULTANT = 'Consultant',
  CONTENT_MANAGER = 'Content Manager',
  COVER_ART_DESIGNER = 'Cover Art Designer',
  CREATIVE_DIRECTOR = 'Creative Director',
  CTO = 'CTO',
  DEVELOPMENT_PRODUCER = 'Development Producer',
  DIRECTOR = 'Director',
  EDITOR = 'Editor',
  EDITORIAL_DIRECTOR = 'Editorial Director',
  EXECUTIVE_PRODUCER = 'Executive Producer',
  FACT_CHECKER = 'Fact Checker',
  FOLEY_ARTIST = 'Foley Artist',
  FOUNDER = 'Founder',
  GRAPHIC_DESIGNER = 'Graphic Designer',
  GUEST_HOST = 'Guest Host',
  INTERN = 'Intern',
  LIGHTING_DESIGNER = 'Lighting Designer',
  LOGGER = 'Logger',
  MANAGING_EDITOR = 'Managing Editor',
  MARKETING_DIRECTOR = 'Marketing Director',
  MARKETING_MANAGER = 'Marketing Manager',
  MUSIC_CONTRIBUTOR = 'Music Contributor',
  MUSIC_PRODUCTION = 'Music Production',
  MUSIC_SUPERVISOR = 'Music Supervisor',
  NARRATOR = 'Narrator',
  PLAYER = 'Player',
  PODCAST_CONCEPT_DEVELOPER = 'Podcast Concept Developer',
  POST_PRODUCTION_ENGINEER = 'Post Production Engineer',
  PRODUCER = 'Producer',
  PRODUCTION_ASSISTANT = 'Production Assistant',
  PRODUCTION_COORDINATOR = 'Production Coordinator',
  REMOTE_RECORDING_ENGINEER = 'Remote Recording Engineer',
  REPORTER = 'Reporter',
  RESEARCHER = 'Researcher',
  SALES_DIRECTOR = 'Sales Director',
  SALES_MANAGER = 'Sales Manager',
  SALES_REPRESENTATIVE = 'Sales Representative',
  SCRIPT_COORDINATOR = 'Script Coordinator',
  SCRIPT_EDITOR = 'Script Editor',
  SENIOR_PRODUCER = 'Senior Producer',
  SINGER = 'Singer',
  SOCIAL_MEDIA_MANAGER = 'Social Media Manager',
  SONGWRITER = 'Songwriter',
  SOUND_DESIGNER = 'Sound Designer',
  STORY_EDITOR = 'Story Editor',
  STUDIO_COORDINATOR = 'Studio Coordinator',
  TALENT_MANAGER = 'Talent Manager',
  TECHNICAL_DIRECTOR = 'Technical Director',
  TECHNICAL_MANAGER = 'Technical Manager',
  THEME_MUSIC = 'Theme Music',
  TRANSCRIBER = 'Transcriber',
  TRANSLATOR = 'Translator',
  VALUE_JOCK = 'Value Jock',
  VOICE_ACTOR = 'Voice Actor',
  WRITER = 'Writer'
}

export interface PodcastImage {
  url: string;
  link?: string;
  title?: string;
}

export type Explicit = 'clean' | 'false' | 'no' | 'true' | 'yes' | boolean;

export interface Itunes {
  categories?: string[];
  season?: number;
  episode?: number;
  duration?: string;
  subtitle: string;
  keywords?: string[];
  owner: {
    email: string;
    name?: string;
  };
  explicit: Explicit;
  author: string;
  image: string;
  summary: string;
}

export interface Location {
  name: string;
  geo?: string;
  osm?: string;
}

export interface PodcastLicense {
  value: string;
  url?: string;
}

export interface Trailer {
  value: string;
  url: string;
  pubDate: string;
  length?: number;
  type?:
    | 'application/x-bittorrent'
    | 'application/x-mpegURL'
    | 'audio/aac'
    | 'audio/midi'
    | 'audio/mpeg'
    | 'audio/ogg'
    | 'audio/wav'
    | 'audio/webm'
    | 'audio/x-m4a'
    | 'audio/x-midi'
    | 'video/3gpp'
    | 'video/MP2T'
    | 'video/mp4'
    | 'video/quicktime'
    | 'video/x-flv'
    | 'video/x-ms-wmv'
    | 'video/x-nsvideo';
  season?: number;
  inputValue?: string;
}

export enum PodcastType {
  EPISODIC = 'episodic',
  SERIAL = 'serial'
}

export type PodcastFrequency = {
  label: string;
  rrule?: string;
  complete?: boolean;
  dtstart?: Date;
};

export enum Medium {
  AUDIO_BOOK = 'audiobook',
  PODCAST = 'podcast',
  MUSIC = 'music',
  BLOG = 'blog',
  COURSE = 'course',
  EVENT = 'event',
  FILM = 'film',
  NEWS_LETTER = 'newsletter',
  RADIO = 'radio',
  VIDEO = 'video'
}

export interface Social {
  platform: string;
  id: string;
  url: string;
}

export enum PodcastValue4ValueType {
  BITCOIN = 'bitcoin',
  LIGHTNING = 'lightning',
  KEYSEND = 'keysend',
  AMP = 'amp',
  WALLET = 'wallet',
  NODE = 'node'
}

export interface PodcastValue4ValueRecipient {
  type: PodcastValue4ValueType;
  address: string;
  split: number;
  name?: string;
  customKey?: string;
  customValue?: string;
  fee?: boolean;
}

export interface PodcastValue4ValueMeta {
  type: PodcastValue4ValueType;
  method: string;
  suggested?: number;
}

export type ValueTimeSplit = {
  recipients: PodcastValue4ValueRecipient[];
  feedGuid: string;
  feedTitle?: string;
  itemGuid: string;
  startTime: number;
  duration: number;
  remotePercentage: number;
};

export type V4VRecipient = Pick<
  PodcastValue4ValueRecipient,
  'address' | 'customKey' | 'customValue' | 'split' | 'type' | 'fee'
> & { lightningAddress?: string; name: string; totalEarned?: number };

export interface Value4ValueType {
  type: PodcastValue4ValueType;
  method: string;
  recipients: V4VRecipient[];
  timeSplit?: ValueTimeSplit[];
  budget?: number;
  superfanSplit?: number;
  suggested?: {
    boost?: number;
    clip?: number;
    replies?: number;
    play?: number;
    trailer?: number;
  };
  totalPrice?: number;
  totalPriceCurrency?: CurrencyUnit;
  fixedPrice?: boolean;
  enabled?: boolean;
  locked?: boolean;
}

export interface IPodcastPerson {
  name: string;
  user?: BriefUser;
  role?: string;
  roles?: string[];
  group?: string;
  img?: string;
  updated?: number;
}

export enum LiveItemStatus {
  PENDING = 'pending',
  LIVE = 'live',
  ENDED = 'ended'
}

export type PodcastChatType = {
  server: string;
  protocol?: string;
  space?: string;
  accountId?: string;
  embedUrl?: string;
};

export type PodcastLiveValueType = {
  uri: string;
  protocol?: string;
};

export interface LiveItem {
  status: LiveItemStatus;
  start: Date;
  end: Date;
  title: string;
  description: string;
  link: string;
  guid: string;
  enclosure: Enclosure;
  contentLink?: { label?: string; href: string };
  chat?: PodcastChatType;
  liveValue?: PodcastLiveValueType;
  socialInteract?: SocialInteract;
}

export enum AcceptsGuests {
  YES = 'yes',
  NO = 'no',
  Maybe = ''
}

export enum PodcastTabs {
  episodes = 'episodes',
  activities = 'activity',
  boosts = 'boosts',
  clips = 'clips',
  reviews = 'reviews',
  recommendations = 'recommendations',
  leaderboard = 'leaderboard',
  events = 'events',
  blog = 'blog',
  playedOn = 'playedon',
  merch = 'merch'
}

export interface IPodcast extends MongoDoc {
  copyright?: string;
  completedAt?: string;
  title: string;
  slug: string;
  feedUrl: string;
  description: string;
  image: PodcastImage;
  itunes: Itunes;
  language: string;
  subscriptionCount?: number;
  playCount?: number;
  clipCount?: number;
  persons?: IPodcastPerson[];
  link?: string;
  acceptsGuests?: AcceptsGuests;
  domains?: string[];
  images?: string[];
  updatedAt: string;
  pubDate?: string;
  lastBuildDate?: string;
  locked: 'false' | 'no' | 'true' | 'yes';
  value4value?: Value4ValueType;
  updateFrequency: PodcastFrequency;
  license?: PodcastLicense;
  location?: Location;
  type?: PodcastType;
  medium?: Medium;
  episodeCount: number;
  seasonCount: number;
  boostCount?: number;
  sats?: number;
  featuredEpisode?: string;
  featured?: boolean;
  homeFeatured?: boolean;
  claimed?: boolean;
  usesPodping?: boolean;
  supportEnabled?: boolean;
  completed?: boolean;
  dead?: boolean;
  failed?: boolean;
  aiGenerated?: boolean;
  guid?: string;
  creator?: string;
  generator?: string;
  country?: CountryCodeType;
  funding?: string;
  producer?: string | { name: string; slug: string };
  artist?: string | { name: string; slug: string };
  host?: string;
  isbn?: string;
  shareholderURL?: string;
  podroll?: string[];
  podrollEnabled?: boolean;
  singleItem?: boolean;
  keepLocalFields?: boolean;
  trailer?: Enclosure;
  youtubePlaylistId?: string;
  commentsDisabled?: boolean;
  chaptersRequestDisabled?: boolean;
  transcriptRequestDisabled?: boolean;
  tabs?: PodcastTabs[];
  liveItem?: LiveItem;
  latestEpisode?: Pick<IEpisode, '_id' | 'pubDate'> & Pick<Itunes, 'season' | 'episode'>;
}

export type PodcastCardType = Pick<IPodcast, 'latestEpisode' | '_id' | 'title' | 'image' | 'slug'>;

export type PopulatedEpisodeType = Pick<IEpisode, '_id' | 'title'> & {
  podcast: PodcastCardType;
};

export type PodcastLatestEpisodeType = Pick<
  IPodcast,
  | '_id'
  | 'title'
  | 'image'
  | 'slug'
  | 'playCount'
  | 'boostCount'
  | 'clipCount'
  | 'liveItem'
  | 'guid'
> & {
  activityId: string;
  subscribed: boolean;
  episode: IEpisode;
};

export interface CreatePodcastFormType {
  url: string;
  podcastId?: string;
  users?: string[];
  customMessage?: string;
  suggestId?: string;
}

export type PodcastEpisodeFormType = IPodcast | IEpisode;

export interface HookFormProps {
  register: UseFormRegister<PodcastEpisodeFormType>;
  control: Control<PodcastEpisodeFormType>;
}

export enum SuggestionStatus {
  ADDED = 'added',
  FAILED = 'failed',
  PENDING = 'pending'
}

export interface ISuggestion {
  _id: string;
  url: string;
  feedId: number;
  title: string;
  image?: string;
  exists?: boolean;
  status?: SuggestionStatus;
  updatedAt: string;
}

export type SuggestedPodcastType = {
  id: number;
  lastUpdateTime: number;
  voteCount: number;
  suggester?: BriefUser;
  title: string;
  image: string;
  episodeCount: number;
  status: string;
  guid: string;
};

export enum TaskStatus {
  VALID = 'valid',
  MISSING = 'missing'
}

export type PodcastTaskType = {
  _id: string;
  message: string;
  updatedAt: string;
  feature: string;
  status: TaskStatus;
};

export interface PodcastPodrollResponse {
  podcasts: PodcastLatestEpisodeType[];
  suggestedPodcasts: SuggestedPodcastType[];
}

export interface PodcastTasksResponse {
  tasks: PodcastTaskType[];
  toDoCount: number;
}

export interface SearchPodcastsResponse {
  totalDocs: number;
  searchTerm?: string;
  data: {
    podcasts: IPodcast[];
    suggestedPodcasts: SuggestedPodcastType[];
  };
}

export interface ISupport {
  _id: string;
  episode: { _id: string; title: string; image?: string };
  createdAt: string;
  paid: SatsFieldType;
}

export interface IActivityValue {
  action: ActivityAction;
  value: SatsFieldType;
  percentage: number;
}

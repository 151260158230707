import { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

import { Color, theme } from 'theme/muiTheme';

const MaterialProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'inherit'
          },
          html: {
            fontSize: '62.5%',
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',

            [theme.breakpoints.up('bigDesktop')]: {
              fontSize: '75%'
            },

            [theme.breakpoints.down('desktop')]: {
              fontSize: '56.25%'
            }
          },
          body: {
            boxSizing: 'border-box',
            fontSize: '1.5rem',
            fontFamily: 'Montserrat',
            color: Color.NTR_400,
            padding: '0 !important',
            fontWeight: 500,
            lineHeight: 1.5
          },
          a: {
            textDecoration: 'none',
            display: 'block'
          },
          textarea: {
            '&.MuiInputBase-input': {
              lineHeight: 1.4
            }
          },
          figure: {
            position: 'relative'
          },
          label: {
            marginBottom: 8,
            fontWeight: 500
          },

          br: {
            margin: '4px 0',
            display: 'block',
            content: '""'
          },

          '.pac-container': {
            zIndex: 999999
          },

          '.badge': {
            backgroundColor: Color.PRM_200,
            color: Color.WHITE,
            margin: '0 10px'
          },

          '.settings-container': {
            display: 'flex',
            gap: '32px'
          },

          '.custom-badge': {
            backgroundColor: Color.PRM_400,
            color: Color.WHITE,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            padding: 4,
            width: 20,
            height: 20,
            borderRadius: '50%'
          },

          '.ellipsis': {
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },

          '.rolling-anim': {
            animation: 'rotate 1s infinite'
          },

          '.rolling-anim.reverse': {
            animation: 'rotate 1s infinite',
            animationDirection: 'reverse'
          },

          '@keyframes rotate': {
            '0%': {
              transform: 'rotate(360deg)'
            },
            '100%': {
              transform: 'rotate(0deg)'
            }
          }
        }}
      />

      {children}
    </ThemeProvider>
  );
};

export default MaterialProvider;

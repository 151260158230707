import { MongoDoc } from './general';
import { IPodcast } from './podcastInterface';

export enum LogLevel {
  ERROR = 'error',
  INFO = 'info'
}

export enum LogTag {
  PODPING = 'podping',
  PODCAST_INDEX = 'podcastindex',
  PODCAST = 'Podcast',
  EPISODE = 'Episode',
  TOPUP = 'TopUp',
  PAYMENT = 'Payment',
  COMMENT = 'Boost',
  CLIP = 'Clip',
  SUBSCRIPTION = 'Subscription',
  ZAP = 'Zap',
  RATING = 'Rating'
}

export interface ILog extends MongoDoc {
  tag: LogTag;
  level: LogLevel;
  payload: Record<string, unknown>;
  podcast?: Pick<IPodcast, 'title' | 'slug' | 'feedUrl'> & { image: string; id: string };
}

import type { ActivityAudioType } from './activityInterface';
import { CurrencyUnit, MongoDoc } from './general';
import type {
  IPodcastPerson,
  PodcastLicense,
  Location,
  Itunes,
  IPodcast,
  Value4ValueType,
  PodcastTabs
} from './podcastInterface';
import type { BriefUser } from './userInterface';

export enum MediaMimeType {
  /** AUDIO */
  MPEG = 'audio/mpeg',
  ACC = 'audio/aac',
  MIDI = 'audio/midi',
  X_MIDI = 'audio/x-midi',
  OGG = 'audio/ogg',
  WAV = 'audio/wav',
  WEBM = 'audio/webm',
  X_M4A = 'audio/x-m4a',
  MP3 = 'audio/mp3',
  OPUS = 'audio/opus',
  /** VIDEO */
  FLASH = 'video/x-flv',
  MP4 = 'video/mp4',
  VIDEO_PEERTUBE = 'video/peertube',
  VIDEO_YOUTUBE = 'video/youtube',
  IPHONE_INDEX = 'application/x-mpegURL',
  APPLE_INDEX = 'application/vnd.apple.mpegurl',
  IPHONE_SEGMENT = 'video/MP2T',
  GP3 = 'video/3gpp',
  QUICK_MOVE = 'video/quicktime',
  AV = 'video/x-nsvideo',
  WINDOWS_MEDIA = 'video/x-ms-wmv',
  X_BITTORRENT = 'application/x-bittorrent'
}

export enum SocialInteractProtocol {
  DISABLED = 'disabled',
  ACTIVITYPUB = 'activitypub',
  TWITTER = 'twitter',
  ATPROTO = 'atproto',
  LIGHTNING = 'lightning'
}

export interface SocialInteract {
  protocol: SocialInteractProtocol;
  uri: string;
  accountId?: string;
  accountUrl?: string;
}

export enum DocMimeType {
  TEXT = 'text/plain',
  HTML = 'text/html',
  VTT = 'text/vtt',
  JSON = 'application/json',
  JSON_CHAPTERS = 'application/json+chapters',
  X_SUBRIP = 'application/x-subrip',
  SRT = 'application/srt'
}

export interface Enclosure {
  url: string;
  length: number;
  type: MediaMimeType;
}

export interface Transcript {
  url: string;
  type: DocMimeType;
}

export interface IChapter {
  title: string;
  startTime: string;
  img?: string;
  url?: string;
}

export enum EpisodeType {
  FULL = 'full',
  TRAILER = 'trailer',
  BONUS = 'bonus'
}

export type PartialPodcast = Pick<
  IPodcast,
  | '_id'
  | 'title'
  | 'episodeCount'
  | 'seasonCount'
  | 'slug'
  | 'persons'
  | 'image'
  | 'medium'
  | 'value4value'
  | 'creator'
>;

export type AlternateEnclosure = {
  url: string;
  type: MediaMimeType;
  length?: number;
  title?: string;
  default?: boolean;
};

export type SatsFieldType = {
  sats: number;
  fiat: number;
  formattedSats: string;
  formattedFiat: string;
};

export enum EpisodeStatus {
  NEW = 'NEW',
  RESUME = 'RESUME',
  PLAY = 'PLAY',
  FINISHED = 'FINISHED'
}

export interface LastPlayActivity {
  _id: string;
  duration: number;
  start: number;
  end: number;
  createdAt: string;
}

export interface EpisodeMeta {
  valuePerMin?: number;
  valueRemaining?: SatsFieldType;
  totalValue?: SatsFieldType;
  totalPaid?: SatsFieldType;
  totalZapped?: SatsFieldType;
  currency: CurrencyUnit;
  status: EpisodeStatus;
  supported?: boolean;
  lastPlay?: LastPlayActivity;
}

export interface IEpisode extends MongoDoc {
  title: string;
  description: string;
  itunes: Itunes;
  type: EpisodeType;
  podcast: string | PartialPodcast;
  guid: string;
  link: string;
  pubDate: string;
  enclosure: Enclosure;
  updatedAt: string;
  playCount?: number;
  transcript?: Transcript;
  chapters?: IChapter[];
  socialInteract?: SocialInteract;
  persons?: IPodcastPerson[];
  value4value?: Value4ValueType;
  location?: Location;
  season?: {
    value: number;
    name?: string;
  };
  episode?: {
    value: number;
    display?: string;
  };
  license?: PodcastLicense;
  alternateEnclosures?: AlternateEnclosure[];
  images?: string[];
  recommendations: { url: string; type: string; language?: string; value?: string }[];
  isrc?: string;
  biteCount?: number;
  clipCount?: number;
  boostCount?: number;
  sats?: number;
  commentsDisabled?: boolean;
  chaptersRequestDisabled?: boolean;
  transcriptRequestDisabled?: boolean;
  meta?: EpisodeMeta;
  tabs?: PodcastTabs[];
}

export type BriefEpisode = Pick<IEpisode, '_id' | 'title' | 'enclosure' | 'itunes' | 'podcast'>;

export type BoostPaymentType = {
  paid?: number;
  earned?: number;
};

export interface IBoost {
  boostedAt: string;
  message: string;
  createdAt: string;
  episode: string | BriefEpisode;
  updatedAt: string;
  user: BriefUser;
  replies?: number;
  payment?: BoostPaymentType;
  flags?: string[];
  _id: string;
}

export type BoostFormType = Pick<IBoost, 'message' | 'boostedAt'> & {
  parent?: string;
};
export type PopulatedBoost = Omit<IBoost, 'episode'> & {
  episode?: Omit<BriefEpisode, 'podcast'> & { podcast: PartialPodcast };
};

export enum ClipType {
  SOUNDBITE = 'soundbite',
  CLIP = 'clip'
}

export interface IClip {
  createdAt: string;
  duration: string;
  episode: string | BriefEpisode;
  startTime: string;
  updatedAt: string;
  user: BriefUser;
  type: ClipType;
  title?: string;
  payment?: BoostPaymentType;
  flags?: string[];
  _id: string;
}

export type ClipFormType = Pick<IClip, 'duration' | 'startTime' | 'title'> & { type?: ClipType };

export type BriefEpisodeWithPodcast = Omit<BriefEpisode, 'podcast'> & { podcast: PartialPodcast };

export type PopulatedClip = Omit<IClip, 'episode'> & {
  episode?: BriefEpisodeWithPodcast;
};

export enum ReportType {
  AI = 'AI',
  EXPLICIT = 'Explicit',
  OTHER = 'Other'
}

export type PopulatedReport = {
  _id: string;
  createdAt: string;
  type: ReportType;
  user: BriefUser;
  message?: string;
  episode?: BriefEpisodeWithPodcast;
  boost?: { _id: string; episode: BriefEpisodeWithPodcast };
  clip?: { _id: string; episode: BriefEpisodeWithPodcast };
};

export interface IEpisodesStats {
  // seasons: number;
  episodeCount: number;
  newPodcasts: number;
  v4vPodcasts: number;
  activePodcasts: number;
  inactivePodcasts: number;
  failedPodcasts: number;
  completedPodcasts: number;
}

export interface AudioStats {
  episodeId: string;
  start: number;
  end: number;
  duration: number;
  audioType: ActivityAudioType;
}

export interface ISupporter {
  _id: string;
  user: BriefUser;
  createdAt: string;
  paid: SatsFieldType;
  active: boolean;
}
